import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

function PrivacyPolicy() {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  const location = useLocation();
  const currentURL = `https://malayalamnews.live/${location.pathname}`;

  return (
    <>
      <div className="Apps">
        <Helmet>
          <link rel="canonical" href={currentURL} />
          <title>
            Latest Malayalam News, Breaking Malayalam News | Malayalam Live
          </title>
          <meta
            name="description"
            content="Get instant access to Malayalam News Live, with the latest updates from trusted sources like Asianet, Manorama, Mathrubhumi, and more. Stay informed on local, national, and global events."
          />
          <meta
            name="keywords"
            content="Malayalam news, Malayalam News Live, latest malayalam news, top malayalam news, breaking malayalam news, Asianet News, Manorama News, Mathrubhumi News, 24 News, News18 Kerala, Reporter TV, MediaOne, Kairali News, JANAM TV"
          />
        </Helmet>
        <Header />

        <div className="privacy-policy">
          <div className="container">
            <button className="back-arrow" onClick={goBack}>
              <i className="fa fa-long-arrow-left"></i>
            </button>
            <h1>Privacy Policy</h1>
          </div>

          <p>
            Welcome to www.malayalamnews.live. This Privacy Policy outlines how
            we collect, use, and protect your personal information when you
            access our website and services. By using our website, you agree to
            the practices described in this policy.
          </p>
          <ol className="bullet-list">
            <li>
              <h2>Information We Collect</h2>
              <p>We may collect two types of information from users:</p>
              <ol className="lower-alpha">
                <li>
                  <h3>Personal Information</h3>: We do not collect personal
                  information such as names, email addresses, or phone numbers
                  unless you provide it voluntarily (e.g., through contact
                  forms, newsletters, or account creation).
                </li>
                <li>
                  <h3>Non-Personal Information</h3>: We collect non-identifiable
                  information such as IP addresses, browser types, pages
                  visited, and the time spent on our website. This information
                  helps us understand how our users interact with our site and
                  improve its performance.
                </li>
              </ol>
            </li>
            <li>
              <h2>Cookies and Tracking Technologies</h2>
              <p>
                We use cookies and similar tracking technologies to improve your
                experience on our website. Cookies help us analyze traffic,
                customize content, and enhance user experience. You can manage
                or disable cookies through your browser settings, but this may
                affect the functionality of certain features on our site.
              </p>
            </li>
            <li>
              <h2>Use of Information</h2>
              <p>
                The information we collect may be used for the following
                purposes:
              </p>
              <ul className="dash-list">
                <li>
                  To improve our website’s performance and content delivery.
                </li>
                <li> To personalize user experience.</li>
                <li>To monitor and analyze usage trends and preferences.</li>
                <li>
                  To comply with legal obligations or protect the rights of
                  www.malayalamnews.live.
                </li>
              </ul>
            </li>
            <li>
              <h2>Content Ownership and Intellectual Property</h2>
              <p>
                All news articles, videos, and images featured on
                www.malayalamnews.live are the property of their respective. We
                do not claim ownership over any third-party content displayed on
                our platform. If you believe that any content violates copyright
                laws, please contact us for prompt resolution.
              </p>
            </li>
            <li>
              <h2>Third-Party Links</h2>
              <p>
                Our website may contain links to third-party websites or
                services. We are not responsible for the privacy practices or
                content of these external sites. We encourage you to review the
                privacy policies of any external websites before providing
                personal information.
              </p>
            </li>
            <li>
              <h2>Data Security</h2>
              <p>
                We take reasonable measures to protect the information collected
                through our website from unauthorized access, use, or
                disclosure. However, no method of online transmission is 100%
                secure, and we cannot guarantee the absolute security of your
                data.
              </p>
            </li>
            <li>
              <h2>Children's Privacy</h2>
              <p>
                Our website is not intended for use by children under the age of
                13. We do not knowingly collect personal information from
                children. If you are a parent or guardian and believe your child
                has provided us with personal information, please contact us,
                and we will take steps to remove such information.
              </p>
            </li>
            <li>
              <h2>Changes to This Policy</h2>
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal obligations. Any changes will
                be posted on this page, and the updated policy will take effect
                immediately upon posting.
              </p>
            </li>
            <li>
              <h2> Contact Us</h2>
              <p>
                If you have any questions or concerns regarding this Privacy
                Policy or the practices of www.malayalamnews.live, please
                contact us at: Email: contact@malayalamnews.live
              </p>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
