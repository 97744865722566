import React, { useContext, useEffect, useState } from "react";
import { cacheBuster } from "./config";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "zeemalayalamnews", For eg: "newlogoname",
  const [tabs] = useState([
    "asianet",
    "manorama",
    "mathrubhumi",
    "twentyfour",
    "news18",
    "reporter",
    "mediaone",
    "kairalinews",
    "janamtv",
    "zeemalayalamnews",
  ]);

  const [selectedMalayalamTab, setSelectedMalayalamTab] = useState<string>(
    () => localStorage.getItem("selectedMalayalamTab") || "asianet"
  );

  // Handle tab selection
  const handleTabClick = (channelKey: string) => {
    setSelectedMalayalamTab(channelKey);
    localStorage.setItem("selectedMalayalamTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedMalayalamTab");
    if (savedTab) {
      setSelectedMalayalamTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["cnbctv18", "cnnnews18"];
  const hiddenTabs: any = ["manorama", "zeemalayalamnews"];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedMalayalamTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedMalayalamTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const asianet = `/assets/channel-logos/asianet.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/

  const manorama = `/assets/channel-logos/manorama.png?v=${cacheBuster}`;
  const mathrubhumi = `/assets/channel-logos/matrubhumi.png?v=${cacheBuster}`;
  const twentyfour = `/assets/channel-logos/twentyfour.png?v=${cacheBuster}`;
  const news18 = `/assets/channel-logos/news18.png?v=${cacheBuster}`;
  const reporter = `/assets/channel-logos/reporter.png?v=${cacheBuster}`;
  const mediaone = `/assets/channel-logos/mediaone.png?v=${cacheBuster}`;
  const kairalinews = `/assets/channel-logos/kairalinews.png?v=${cacheBuster}`;
  const janamtv = `/assets/channel-logos/janamtv.png?v=${cacheBuster}`;
  const zeemalayalamnews = `/assets/channel-logos/zeemalayalamnews.png?v=${cacheBuster}`;

  const imagesToPreload = [
    asianet,
    manorama,
    mathrubhumi,
    twentyfour,
    news18,
    reporter,
    mediaone,
    kairalinews,
    janamtv,
    zeemalayalamnews,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Latest Malayalam News, Breaking Malayalam News | Malayalam Live
          </title>
          <meta
            name="description"
            content="Get instant access to Malayalam News Live, with the latest updates from trusted sources like Asianet, Manorama, Mathrubhumi, and more. Stay informed on local, national, and global events."
          />
          <meta
            name="keywords"
            content="Malayalam news, Malayalam News Live, latest malayalam news, top malayalam news, breaking malayalam news, Asianet News, Manorama News, Mathrubhumi News, 24 News, News18 Kerala, Reporter TV, MediaOne, Kairali News, JANAM TV"
          />
        </Helmet>

        <Header />

        {selectedOption === "Malayalam" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 126 to 145) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("asianet") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "asianet" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("asianet")}
                  >
                    {/**** When you add new logo change the src={asianet} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={asianet}
                        alt="Asianet News"
                      />
                    </div>
                    {/**** When you add new logo change the src={asianet} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("manorama") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "manorama" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("manorama")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={manorama}
                        alt="Manorama News"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("mathrubhumi") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "mathrubhumi" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("mathrubhumi")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={mathrubhumi}
                        alt="Mathrubhumi News"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("twentyfour") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "twentyfour" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("twentyfour")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={twentyfour}
                        alt="Twenty Four"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "news18" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("news18")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18}
                        alt="News 18"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("reporter") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "reporter" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("reporter")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={reporter}
                        alt="Reporter"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("mediaone") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "mediaone" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("mediaone")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={mediaone}
                        alt="Media One"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("kairalinews") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "kairalinews" ? "active" : ""
                    } order-8`}
                    onClick={() => handleTabClick("kairalinews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={kairalinews}
                        alt="Kairali News"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("janamtv") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "janamtv" ? "active" : ""
                    } order-9`}
                    onClick={() => handleTabClick("janamtv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={janamtv}
                        alt="Janam TV"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zeemalayalamnews") && (
                  <div
                    className={`tab-item ${
                      selectedMalayalamTab === "zeemalayalamnews"
                        ? "active"
                        : ""
                    } order-9`}
                    onClick={() => handleTabClick("zeemalayalamnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zeemalayalamnews}
                        alt="Zee Malayalam News"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video  */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedMalayalamTab === "asianet" &&
                visibleTabs.includes("asianet") && (
                  <>
                    <h1>Asianet News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Ko18SgceYX8?autoplay=1&v=${cacheBuster}`}
                      title="Asianet News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedMalayalamTab === "manorama" &&
                visibleTabs.includes("manorama") && (
                  <>
                    <h1>Manorama News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/tgBTspqA5nY?autoplay=1&v=${cacheBuster}`}
                      title="Manorama News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "mathrubhumi" &&
                visibleTabs.includes("mathrubhumi") && (
                  <>
                    <h1>Mathrubhumi News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/YGEgelAiUf0?autoplay=1&v=${cacheBuster}`}
                      title="Mathrubhumi News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "twentyfour" &&
                visibleTabs.includes("twentyfour") && (
                  <>
                    <h1>24 News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/1wECsnGZcfc?autoplay=1&v=${cacheBuster}`}
                      title="24 News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "news18" &&
                visibleTabs.includes("news18") && (
                  <>
                    <h1>News18 Kerala Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/oysB2PtPNXs?si=ikqgXbUZViUE7_PN&autoplay=1&v=${cacheBuster}`}
                      title="News18 Kerala Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "reporter" &&
                visibleTabs.includes("reporter") && (
                  <>
                    <h1>Reporter TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/HGOiuQUwqEw?autoplay=1&v=${cacheBuster}`}
                      title="Reporter TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "mediaone" &&
                visibleTabs.includes("mediaone") && (
                  <>
                    <h1>MediaOne TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/-8d8-c0yvyU?autoplay=1&v=${cacheBuster}`}
                      title="MediaOne TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "kairalinews" &&
                visibleTabs.includes("kairalinews") && (
                  <>
                    <h1>Kairali News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/wq0ecjkN3G8?autoplay=1&v=${cacheBuster}`}
                      title="Kairali News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "janamtv" &&
                visibleTabs.includes("janamtv") && (
                  <>
                    <h1>JANAM TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/DxK8pVYckWo?si=Lpds6eF5qUR0Kn6U&autoplay=1&v=${cacheBuster}`}
                      title="JANAM TV Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedMalayalamTab === "zeemalayalamnews" &&
                visibleTabs.includes("zeemalayalamnews") && (
                  <>
                    <h1>Zee Malayalam News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/1stXsSg2pis?si=aTwAxq2kqTFNSDdO&autoplay=1&v=${cacheBuster}`}
                      title="Zee Malayalam News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}
      </div>
      <div style={{ display: "none" }}>
        <p>
          Welcome to Malayalam News Live, your ultimate destination to watch
          live Malayalam news channels online. Our platform is designed to bring
          you real-time updates, breaking news, and in-depth reporting from
          Kerala and across the globe. With a curated collection of the most
          trusted Malayalam news channels, we ensure that you stay connected
          with the latest developments in politics, business, sports,
          entertainment, and local affairs. Whether you’re at home, work, or on
          the go, you can access all your favorite Malayalam news channels
          conveniently in one place.
        </p>

        <p>
          At Malayalam News Live, we feature popular news channels like Asianet
          News Live, Mathrubhumi News Live, 24 News Live, News18 Kerala Live,
          Reporter TV Live, MediaOne TV Live, Kairali News Live, and JANAM TV
          Live. These channels provide accurate and unbiased news coverage,
          catering to a wide range of audiences. From political developments to
          cultural updates, our platform ensures you are always informed about
          what matters most in Kerala and beyond. Watching your preferred
          Malayalam news live channels has never been easier—just a click away!
        </p>

        <p>
          Our platform stands out by offering a seamless viewing experience with
          user-friendly navigation and uninterrupted streaming. We understand
          the importance of staying updated, which is why Malayalam News Live is
          designed to provide easy access to all major Malayalam live news
          channels. Whether it’s a breaking story, election results, or live
          coverage of an event, you can rely on us for up-to-the-minute updates.
          Bookmark our site and stay ahead of the curve with the latest news
          from the most reliable Malayalam channels.
        </p>

        <p>
          Stay informed with the convenience of accessing all the top Malayalam
          news channels live from one platform. Gone are the days of searching
          multiple websites or apps to find live streaming of your favorite
          Malayalam channels. With Malayalam News Live, you have everything you
          need at your fingertips. Our platform ensures high-quality streaming,
          making it easier for you to catch live updates anytime, anywhere.
          Don’t miss out on the most important headlines and live events—our
          platform is your go-to source for Malayalam live news.
        </p>

        <p>
          Join thousands of viewers who trust Malayalam News Live for their
          daily dose of Malayalam news. We are committed to bringing you
          authentic and comprehensive coverage of the latest events, ensuring
          that you stay informed every step of the way. Whether it’s breaking
          news on Asianet News Live, detailed reports on Mathrubhumi News Live,
          or investigative stories on Reporter TV Live, you’ll find it all here.
          Stay connected with the world through Malayalam News Live, your
          trusted partner for live Malayalam news streaming.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
